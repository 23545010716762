import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Offers from './Offers';
import styles from './Pricing.module.scss';

const Pricing = ({ className }) => (
  <section className={classnames(className, styles.pricing)}>
    <header className={styles.pricing__heading}>
      <h2 className={styles.pricing__title}>Get this pack</h2>
    </header>
    <Offers className={styles.pricing__offers} />
  </section>
);

Pricing.propTypes = {
  className: PropTypes.string,
};

Pricing.defaultProps = {
  className: '',
};

export default Pricing;
