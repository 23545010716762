import React, { Children, cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { detect } from 'detect-browser';
import { kebabCase } from 'lodash';
import 'modern-normalize';

import Header, { MODES } from './Header';
import Footer from './Footer';
import './app.scss';
import styles from './Layout.module.scss';

const Layout = ({ children, isSubpage, onStickyChange }) => {
  const [shouldScrollToFooter, setScrollToFooter] = useState(false);
  const browserName = detect().name;
  const osName = kebabCase(detect().os);

  useEffect(() => {
    if (!document.body.classList.contains(browserName)) {
      document.body.classList.add(browserName);
    }

    if (!document.body.classList.contains(osName)) {
      document.body.classList.add(osName);
    }

    if (shouldScrollToFooter) {
      setScrollToFooter(false);
    }
  });

  const getMode = () => (isSubpage ? MODES.SUBPAGE : MODES.MAIN);

  const handleScrollToFooter = (event) => {
    if (event) {
      event.preventDefault();
    }

    setScrollToFooter(true);
  };

  return (
    <>
      <Header
        mode={getMode()}
        onScrollToFooter={handleScrollToFooter}
        onStickyChange={onStickyChange}
      />
      <main>
        {Children.map(children, (child) =>
          cloneElement(child, { onScrollToFooter: handleScrollToFooter })
        )}
      </main>
      <Footer shouldScrollToFooter={shouldScrollToFooter} />
      <a
        className={styles.productHuntBadge}
        href="https://www.producthunt.com/posts/whoooa-illustrations?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-whoooa-illustrations"
        rel="noopener noreferrer"
        target="_blank">
        <img
          alt="🙀 Whoooa! Illustrations - 156 customizable illustrations for your projects | Product Hunt Embed"
          className={styles.productHuntBadge__image}
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=214348&theme=light"
        />
      </a>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
