import React from 'react';
import classnames from 'classnames';
import { ILLUSTRATION_TYPES } from './IndexPage/IllustrationList';
import styles from './OtherIllustrations.module.scss';
import containerStyles from './Container.module.scss';

const DATA = [
  {
    url: 'https://gumroad.com/l/LtatR',
    preview: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/other-1.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/other-1@2x.png',
    },
  },
  {
    url: 'https://gumroad.com/l/NNubu',
    preview: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/other-2.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/other-2@2x.png',
    },
  },
  {
    url: 'https://gumroad.com/l/YkhLs',
    preview: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/other-3.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/other-3@2x.png',
    },
  },
];

export const OtherIllustrations = () => {
  return (
    <section
      className={classnames(
        containerStyles.container,
        styles.otherIllustrations
      )}>
      <header className={styles.otherIllustrations__header}>
        <h2 className={styles.otherIllustrations__title}>
          Other illustrations
        </h2>
      </header>
      <ul className={styles.otherIllustrations__list}>
        {DATA.map(({ url, preview }) => (
          <li className={styles.otherIllustrations__listItem}>
            <a href={url} target="_blank" rel="noopener noreferer">
              <figure className={styles.otherIllustrations__figure}>
                <img
                  className={styles.otherIllustrations__pic}
                  src={preview[ILLUSTRATION_TYPES.DEFAULT]}
                  srcSet={`${preview[ILLUSTRATION_TYPES.DEFAULT]} 1x, ${
                    preview[ILLUSTRATION_TYPES.RETINA]
                  } 2x`}
                  alt="Preview"
                />
              </figure>
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};
