import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import handleViewport from 'react-in-viewport';

import styles from './IllustrationList.module.scss';

export const ILLUSTRATION_STYLES = {
  OUTLINE: 'outline',
  SOLID: 'solid',
};

export const ILLUSTRATION_TYPES = {
  DEFAULT: 'default',
  RETINA: 'retina',
};

const DATA = [
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/developer-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/developer-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/developer-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/developer-1-outline@2x.png',
      },
    },
    name: 'Developer',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/developer-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/developer-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/developer-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/developer-2-outline@2x.png',
      },
    },
    name: 'Developer',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/developer-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/developer-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/developer-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/developer-3-outline@2x.png',
      },
    },
    name: 'Developer',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/designer-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/designer-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/designer-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/designer-1-outline@2x.png',
      },
    },
    name: 'Designer',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/designer-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/designer-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/designer-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/designer-2-outline@2x.png',
      },
    },
    name: 'Designer',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/designer-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/designer-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/designer-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/designer-3-outline@2x.png',
      },
    },
    name: 'Designer',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/e-commerce-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/e-commerce-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/e-commerce-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/e-commerce-1-outline@2x.png',
      },
    },
    name: 'E-commerce',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/e-commerce-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/e-commerce-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/e-commerce-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/e-commerce-2-outline@2x.png',
      },
    },
    name: 'E-commerce',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/e-commerce-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/e-commerce-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/e-commerce-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/e-commerce-3-outline@2x.png',
      },
    },
    name: 'E-commerce',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/error-404-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/error-404-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/error-404-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/error-404-1-outline@2x.png',
      },
    },
    name: 'Error 404',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/error-404-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/error-404-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/error-404-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/error-404-2-outline@2x.png',
      },
    },
    name: 'Error 404',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/error-404-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/error-404-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/error-404-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/error-404-3-outline@2x.png',
      },
    },
    name: 'Error 404',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/payment-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/payment-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/payment-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/payment-1-outline@2x.png',
      },
    },
    name: 'Payment',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/payment-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/payment-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/payment-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/payment-2-outline@2x.png',
      },
    },
    name: 'Payment',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/payment-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/payment-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/payment-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/payment-3-outline@2x.png',
      },
    },
    name: 'Payment',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/search-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/search-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/search-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/search-1-outline@2x.png',
      },
    },
    name: 'Search',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/search-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/search-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/search-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/search-2-outline@2x.png',
      },
    },
    name: 'Search',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/search-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/search-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/search-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/search-3-outline@2x.png',
      },
    },
    name: 'Search',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/team-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/team-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/team-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/team-1-outline@2x.png',
      },
    },
    name: 'Team',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/team-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/team-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/team-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/team-2-outline@2x.png',
      },
    },
    name: 'Team',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/team-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/team-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/team-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/team-3-outline@2x.png',
      },
    },
    name: 'Team',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/under-construction-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/under-construction-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/under-construction-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/under-construction-1-outline@2x.png',
      },
    },
    name: 'Under Construction',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/under-construction-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/under-construction-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/under-construction-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/under-construction-2-outline@2x.png',
      },
    },
    name: 'Under Construction',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/under-construction-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/under-construction-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/under-construction-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/under-construction-3-outline@2x.png',
      },
    },
    name: 'Under Construction',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notifications-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notifications-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notifications-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notifications-1-outline@2x.png',
      },
    },
    name: 'Notifications',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notifications-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notifications-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notifications-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notifications-2-outline@2x.png',
      },
    },
    name: 'Notifications',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notifications-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notifications-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notifications-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notifications-3-outline@2x.png',
      },
    },
    name: 'Notifications',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notification-advanced-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notification-advanced-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notification-advanced-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notification-advanced-1-outline@2x.png',
      },
    },
    name: 'Notifications Advanced',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notification-advanced-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notification-advanced-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notification-advanced-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notification-advanced-2-outline@2x.png',
      },
    },
    name: 'Notifications Advanced',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notification-advanced-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notification-advanced-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/notification-advanced-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/notification-advanced-3-outline@2x.png',
      },
    },
    name: 'Notifications Advanced',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/not-found-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/not-found-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/not-found-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/not-found-1-outline@2x.png',
      },
    },
    name: 'Not Found',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/not-found-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/not-found-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/not-found-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/not-found-2-outline@2x.png',
      },
    },
    name: 'Not Found',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/not-found-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/not-found-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/not-found-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/not-found-3-outline@2x.png',
      },
    },
    name: 'Not Found',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/message-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/message-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/message-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/message-1-outline@2x.png',
      },
    },
    name: 'Message',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/message-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/message-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/message-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/message-2-outline@2x.png',
      },
    },
    name: 'Message',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/message-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/message-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/message-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/message-3-outline@2x.png',
      },
    },
    name: 'Message',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/faq-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/faq-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/faq-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/faq-1-outline@2x.png',
      },
    },
    name: 'FAQ',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/faq-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/faq-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/faq-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/faq-2-outline@2x.png',
      },
    },
    name: 'FAQ',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/faq-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/faq-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/faq-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/faq-3-outline@2x.png',
      },
    },
    name: 'FAQ',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/support-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/support-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/support-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/support-1-outline@2x.png',
      },
    },
    name: 'Support',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/support-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/support-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/support-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/support-2-outline@2x.png',
      },
    },
    name: 'Support',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/support-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/support-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/support-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/support-3-outline@2x.png',
      },
    },
    name: 'Support',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/product-launch-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/product-launch-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/product-launch-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/product-launch-1-outline@2x.png',
      },
    },
    name: 'Product Launch',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/product-launch-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/product-launch-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/product-launch-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/product-launch-2-outline@2x.png',
      },
    },
    name: 'Product Launch',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/product-launch-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/product-launch-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/product-launch-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/product-launch-3-outline@2x.png',
      },
    },
    name: 'Product Launch',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workspace-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/workspace-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workspace-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/workspace-1-outline@2x.png',
      },
    },
    name: 'Workspace',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workspace-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/workspace-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workspace-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/workspace-2-outline@2x.png',
      },
    },
    name: 'Workspace',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workspace-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/workspace-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workspace-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/workspace-3-outline@2x.png',
      },
    },
    name: 'Workspace',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/graphs-statistics-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/graphs-statistics-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/graphs-statistics-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/graphs-statistics-1-outline@2x.png',
      },
    },
    name: 'Graphs & Statistics',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/graphs-statistics-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/graphs-statistics-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/graphs-statistics-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/graphs-statistics-2-outline@2x.png',
      },
    },
    name: 'Graphs & Statistics',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/graphs-statistics-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/graphs-statistics-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/graphs-statistics-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/graphs-statistics-3-outline@2x.png',
      },
    },
    name: 'Graphs & Statistics',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/contact-us-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/contact-us-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/contact-us-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/contact-us-1-outline@2x.png',
      },
    },
    name: 'Contact us',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/contact-us-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/contact-us-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/contact-us-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/contact-us-2-outline@2x.png',
      },
    },
    name: 'Contact us',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/contact-us-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/contact-us-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/contact-us-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/contact-us-3-outline@2x.png',
      },
    },
    name: 'Contact us',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/team-at-work-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/team-at-work-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/team-at-work-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/team-at-work-1-outline@2x.png',
      },
    },
    name: 'Team At Work',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/team-at-work-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/team-at-work-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/team-at-work-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/team-at-work-2-outline@2x.png',
      },
    },
    name: 'Team At Work',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/team-at-work-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/team-at-work-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/team-at-work-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/team-at-work-3-outline@2x.png',
      },
    },
    name: 'Team At Work',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/process-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/process-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/process-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/process-1-outline@2x.png',
      },
    },
    name: 'Process',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/process-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/process-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/process-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/process-2-outline@2x.png',
      },
    },
    name: 'Process',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/process-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/process-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/process-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/process-3-outline@2x.png',
      },
    },
    name: 'Process',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/gaming-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/gaming-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/gaming-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/gaming-1-outline@2x.png',
      },
    },
    name: 'Gaming',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/gaming-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/gaming-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/gaming-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/gaming-2-outline@2x.png',
      },
    },
    name: 'Gaming',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/gaming-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/gaming-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/gaming-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/gaming-3-outline@2x.png',
      },
    },
    name: 'Gaming',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/pandemic-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/pandemic-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/pandemic-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/pandemic-1-outline@2x.png',
      },
    },
    name: 'Pandemic/Medical',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/pandemic-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/pandemic-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/pandemic-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/pandemic-2-outline@2x.png',
      },
    },
    name: 'Pandemic/Medical',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/pandemic-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/pandemic-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/pandemic-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/pandemic-3-outline@2x.png',
      },
    },
    name: 'Pandemic/Medical',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/teaching-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/teaching-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/teaching-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/teaching-1-outline@2x.png',
      },
    },
    name: 'Teaching',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/teaching-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/teaching-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/teaching-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/teaching-2-outline@2x.png',
      },
    },
    name: 'Teaching',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/teaching-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/teaching-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/teaching-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/teaching-3-outline@2x.png',
      },
    },
    name: 'Teaching',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workout-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/workout-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workout-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/workout-1-outline@2x.png',
      },
    },
    name: 'Workout',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workout-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/workout-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workout-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/workout-2-outline@2x.png',
      },
    },
    name: 'Workout',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workout-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/workout-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/workout-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/workout-3-outline@2x.png',
      },
    },
    name: 'Workout',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/hands-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/hands-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/hands-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/hands-1-outline@2x.png',
      },
    },
    name: 'Hands',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/hands-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/hands-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/hands-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/hands-2-outline@2x.png',
      },
    },
    name: 'Hands',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/hands-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/hands-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/hands-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]: '/illustrations/hands-3-outline@2x.png',
      },
    },
    name: 'Hands',
    id: '03',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/construction-1-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/construction-1-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/construction-1-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/construction-1-outline@2x.png',
      },
    },
    name: 'Construction',
    id: '01',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/construction-2-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/construction-2-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/construction-2-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/construction-2-outline@2x.png',
      },
    },
    name: 'Construction',
    id: '02',
  },
  {
    image: {
      [ILLUSTRATION_STYLES.SOLID]: {
        [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/construction-3-solid.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/construction-3-solid@2x.png',
      },
      [ILLUSTRATION_STYLES.OUTLINE]: {
        [ILLUSTRATION_TYPES.DEFAULT]:
          '/illustrations/construction-3-outline.png',
        [ILLUSTRATION_TYPES.RETINA]:
          '/illustrations/construction-3-outline@2x.png',
      },
    },
    name: 'Construction',
    id: '03',
  },
];

const IllustrationList = ({ isChecked }) => {
  return (
    <ul className={styles.illustrationList}>
      {DATA.map((illustration) => (
        <Illustration
          {...illustration}
          isChecked={isChecked}
          key={`${illustration.name}-${illustration.id}`}>
          {illustration.name}
        </Illustration>
      ))}
    </ul>
  );
};

IllustrationList.propTypes = {
  isChecked: PropTypes.bool.isRequired,
};

const Illustration = handleViewport(
  ({
    children,
    enterCount,
    forwardedRef,
    id,
    inViewport,
    isChecked,
    image,
  }) => {
    return (
      <li
        className={classnames(styles.illustration, {
          [styles.illustrationIsVisible]: inViewport || enterCount > 0,
        })}
        ref={forwardedRef}>
        <span className={styles.illustration__label}>
          {children}&nbsp;
          <span className={styles.illustration__labelMinorStyle}>{id}</span>
        </span>
        <div
          className={classnames(styles.illustrationAnimator, {
            [styles.illustrationAnimatorIsActive]: isChecked,
          })}>
          <img
            alt="Illustration solid style"
            className={classnames(styles.illustration__image, {
              [styles.illustration__imageIsActive]: !isChecked,
            })}
            src={image[ILLUSTRATION_STYLES.SOLID][ILLUSTRATION_TYPES.DEFAULT]}
            srcSet={`${
              image[ILLUSTRATION_STYLES.SOLID][ILLUSTRATION_TYPES.DEFAULT]
            } 1x, ${
              image[ILLUSTRATION_STYLES.SOLID][ILLUSTRATION_TYPES.RETINA]
            } 2x`}
          />
          <img
            alt="Illustration outline style"
            className={classnames(styles.illustration__image, {
              [styles.illustration__imageIsActive]: isChecked,
            })}
            src={image[ILLUSTRATION_STYLES.OUTLINE][ILLUSTRATION_TYPES.DEFAULT]}
            srcSet={`${
              image[ILLUSTRATION_STYLES.OUTLINE][ILLUSTRATION_TYPES.DEFAULT]
            } 1x, ${
              image[ILLUSTRATION_STYLES.OUTLINE][ILLUSTRATION_TYPES.RETINA]
            } 2x`}
          />
        </div>
      </li>
    );
  },
  { rootMargin: '10%' }
);

Illustration.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.shape({
    [ILLUSTRATION_STYLES.SOLID]: PropTypes.shape({
      [ILLUSTRATION_TYPES.DEFAULT]: PropTypes.string,
      [ILLUSTRATION_TYPES.RETINA]: PropTypes.string,
    }),
  }).isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default IllustrationList;
