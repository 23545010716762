import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';

import Logo from './Logo';
import MainNav from './MainNav';
import SubpageNav from './SubpageNav';
import styles from './Header.module.scss';

export const MODES = {
  MAIN: 'main',
  SUBPAGE: 'subpage',
};

class Header extends PureComponent {
  static propTypes = {
    mode: PropTypes.oneOf(Object.values(MODES)).isRequired,
    onScrollToFooter: PropTypes.func,
    onStickyChange: PropTypes.func,
  };

  static defaultProps = {
    onScrollToFooter: null,
    onStickyChange: noop,
  };

  state = { isPreparedForStickyMode: false, isStickyMode: false };

  componentDidMount() {
    this.stickyWatcher();
  }

  componentWillUnmount() {
    this.destroyStickyWatcher();
  }

  stickyWatcher() {
    this.detectStickyMode();
    window.addEventListener('scroll', this.detectStickyMode);
  }

  destroyStickyWatcher() {
    window.removeEventListener('scroll', this.detectStickyMode);
  }

  detectStickyMode = () => {
    const { onStickyChange, mode } = this.props;

    if (mode === MODES.SUBPAGE) {
      this.setState({ isPreparedForStickyMode: true });
      this.setState({ isStickyMode: true }, () =>
        onStickyChange(this.state.isStickyMode)
      );
    } else {
      this.setState({ isPreparedForStickyMode: window.scrollY >= 72 });
      this.setState({ isStickyMode: window.scrollY > 100 }, () =>
        onStickyChange(this.state.isStickyMode)
      );
    }
  };

  renderContent() {
    const { mode, onScrollToFooter } = this.props;
    const { isPreparedForStickyMode } = this.state;

    if (!isPreparedForStickyMode) {
      return null;
    }

    if (mode === MODES.SUBPAGE) {
      return <SubpageNav />;
    }

    return <MainNav onScrollToFooter={onScrollToFooter} />;
  }

  render() {
    const { isPreparedForStickyMode, isStickyMode } = this.state;

    return (
      <header
        className={classnames(styles.header, {
          [styles.headerIsPreparedForStickyMode]: isPreparedForStickyMode,
          [styles.headerIsSticky]: isStickyMode,
        })}>
        <h1 className={styles.header__logo}>
          <Link to="/">
            <Logo />
          </Link>
        </h1>
        {this.renderContent()}
      </header>
    );
  }
}

export default Header;
