import React from 'react';
import { Link } from 'gatsby';

import styles from './SubpageNav.module.scss';
import BackArrowIllustration from '../images/back-arrow.inline.svg';

const SubpageNav = () => (
  <nav className={styles.subpageNav}>
    <Link className={styles.subpageNav__link} to="/">
      <BackArrowIllustration className={styles.subpageNav__icon} />
      Go back <span data-hide-on-mobile={true}>&nbsp;to illustrations</span>
    </Link>
  </nav>
);

export default SubpageNav;
