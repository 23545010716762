import React from 'react';
import { Link } from 'gatsby';
import { startsWith } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './FooterNav.module.scss';
import TwitterLogo from '../images/twitter-logo.inline.svg';
import FacebookLogo from '../images/facebook-logo.inline.svg';

const FooterNav = () => (
  <nav className={styles.footerNav}>
    <ul className={styles.footerNavList}>
      <FooterNavLink
        href="https://twitter.com/home?status=https://www.whoooa.rocks/ "
        onlyIcon>
        <TwitterLogo />
        Twitter
      </FooterNavLink>
      <FooterNavLink
        href="https://www.facebook.com/sharer/sharer.php?u=https://www.whoooa.rocks/"
        onlyIcon>
        <FacebookLogo />
        Facebook
      </FooterNavLink>
    </ul>
    <ul className={styles.footerNavList}>
      <FooterNavLink href="mailto:hello@gregdlubacz.com">Contact</FooterNavLink>
      <FooterNavLink href="/faq">FAQ</FooterNavLink>
      <FooterNavLink href="/changelog">Changelog</FooterNavLink>
      <FooterNavLink href="/license">License</FooterNavLink>
    </ul>
  </nav>
);

const FooterNavLink = ({ children, href, onlyIcon }) => {
  const getLinkClassNames = () =>
    classnames(styles.footerNavLink, {
      [styles.footerNavLinkIsOnlyIcon]: onlyIcon,
    });
  return (
    <li className={styles.footerNavItem}>
      {startsWith(href, '/') ? (
        <Link className={getLinkClassNames()} to={href}>
          {children}
        </Link>
      ) : (
        <a className={getLinkClassNames()} href={href} target="_blank">
          {children}
        </a>
      )}
    </li>
  );
};

FooterNavLink.propTypes = {
  children: PropTypes.any.isRequired,
  href: PropTypes.string.isRequired,
  onlyIcon: PropTypes.bool,
};

FooterNavLink.defaultProps = {
  onlyIcon: false,
};

export default FooterNav;
