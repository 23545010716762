import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Offer, { OFFER_TYPES } from './Offer';
import styles from './Offers.module.scss';
import FreeOfferIllustration from '../images/free-offer-illustration.svg';
import OfferIllustration from '../images/offer-illustration.svg';
import OfferVideo from '../images/offer-video.mp4';
import { FREE_PRODUCT_LINK, PRODUCT_LINK } from '../enums/links';

const OFFERS_DATA = [
  {
    features: [
      '20 hand-drawn vector illustrations',
      'Vector + raster formats for all design software',
      'Personal license',
      () => <>A template for Sketch, Figma, Adobe XD for changing colors</>,
    ],
    illustration: FreeOfferIllustration,
    isDisabled: false,
    name: '20 vector illustrations',
    offerLink: FREE_PRODUCT_LINK,
    price: 'Free',
    type: OFFER_TYPES.DEFAULT,
  },
  {
    features: [
      '156 hand-drawn vector illustrations',
      'Vector + raster formats for all design software',
      'Free lifetime updates',
      'Personal & commercial license',
      () => <>A template for Sketch, Figma, Adobe XD for changing colors</>,
      () => (
        <>
          25% of discount on&nbsp;
          <a href="https://oioi.rocks" target="_blank" rel="noreferrer">
            oioi.rocks
          </a>
        </>
      ),
      () => (
        <>
          20% of discount on&nbsp;
          <a href="https://antforfigma.com" target="_blank" rel="noreferrer">
            antforfigma.com
          </a>
        </>
      ),
    ],
    illustration: OfferIllustration,
    isDisabled: false,
    name: '156 vector illustrations',
    offerLink: PRODUCT_LINK,
    price: 49,
    type: OFFER_TYPES.DEFAULT,
  },
  {
    features: [
      'Lottie animated illustrations',
      '156 hand-drawn vector illustrations',
      'Vector + raster formats for all design software',
      'Free lifetime updates',
      'Personal & commercial license',
      'A template for Sketch, Figma, Adobe XD for changing colors',
      'Extra discounts',
    ],
    illustration: OfferVideo,
    isDisabled: true,
    name: 'Animated (Lottie) + Vectors',
    offerLink: '#',
    price: 'Soon',
    type: OFFER_TYPES.PREMIUM,
  },
];

const Offers = ({ className }) => (
  <section className={classnames(className, styles.offers)}>
    {OFFERS_DATA.map((offer) => (
      <Offer {...offer} key={offer.name} />
    ))}
  </section>
);

Offers.propTypes = {
  className: PropTypes.string,
};

Offers.defaultProps = {
  className: '',
};

export default Offers;
