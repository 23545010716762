import React, { createRef, PureComponent } from 'react';
import classnames from 'classnames';

import FooterBottomSection from './FooterBottomSection';
import Pricing from './Pricing';
import styles from './Footer.module.scss';
import { OtherIllustrations } from './OtherIllustrations';

class Footer extends PureComponent {
  footerElement = createRef();

  componentDidUpdate() {
    const { shouldScrollToFooter } = this.props;

    if (shouldScrollToFooter) {
      this.footerElement.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
    return (
      <section className={styles.footer} ref={this.footerElement}>
        <Pricing
          className={classnames(
            styles.footer__container,
            styles.footer__pricing
          )}
        />
        <OtherIllustrations />
        <FooterBottomSection
          className={classnames(
            styles.footer__container,
            styles.footer__bottomSection
          )}
        />
      </section>
    );
  }
}

export default Footer;
