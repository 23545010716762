import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Logo, { LOGO_APPEARANCES } from './Logo';
import FooterNav from './FooterNav';
import styles from './FooterBottomSection.module.scss';

const FooterBottomSection = ({ className }) => (
  <footer className={classnames(className, styles.bottomSection)}>
    <div className={styles.bottomSection__left}>
      <Link className={styles.bottomSection__logoLink} to="/">
        <Logo
          appearance={LOGO_APPEARANCES.WHITE}
          className={styles.bottomSection__logo}
        />
      </Link>
      <p className={styles.bottomSection__copyrightCopy}>
        <span className={styles.bottomSection__copyrightCopyIsNewLine}>
          ©{new Date().getFullYear()} Whoooa!
        </span>{' '}
        Made with 💜 by Ola Maciejewska & Greg Dlubacz.
      </p>
    </div>
    <FooterNav />
  </footer>
);

FooterBottomSection.propTypes = {
  className: PropTypes.string,
};

FooterBottomSection.defaultProps = {
  className: '',
};

export default FooterBottomSection;
