import React from 'react';
import PropTypes from 'prop-types';
import { isFunction, isNumber } from 'lodash';
import classnames from 'classnames';

import styles from './Offer.module.scss';

export const OFFER_TYPES = {
  DEFAULT: '',
  PREMIUM: 'premium',
};

const Offer = ({
  isDisabled,
  features,
  illustration,
  offerLink,
  name,
  price,
  type,
}) => {
  const getPriceCopy = () => {
    if (isNumber(price)) {
      return (
        <>
          <small className={styles.offer__priceVault}>$</small>
          {price}
        </>
      );
    }

    return price;
  };

  const renderVideo = () => {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        autoPlay
        className={styles.offer__illustration}
        loop
        muted
        playsInline
        width="130">
        <source src={illustration} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    );
  };

  const renderIllustration = () => (
    <img
      alt="Illustration"
      className={styles.offer__illustration}
      src={illustration}
    />
  );

  return (
    <section className={styles.offer}>
      <figure className={styles.offer__illustrationWrapper}>
        {type === OFFER_TYPES.PREMIUM ? renderVideo() : renderIllustration()}
      </figure>
      <header className={classnames(styles.offer__heading)}>
        <h3 className={styles.offer__title}>{name}</h3>
      </header>
      <div
        className={classnames(styles.offer__priceWrapper, {
          [styles.offer__priceWrapperIsDisabled]: isDisabled,
        })}>
        <strong className={styles.offer__price}>{getPriceCopy()}</strong>
        <span className={styles.offer__licenseInfo}>Lifetime license</span>
      </div>
      <Features isDisabled={isDisabled} features={features} />
      <Button isDisabled={isDisabled} offerLink={offerLink}>
        {price === 'Free' ? 'Download for free' : 'Buy now'}
      </Button>
      <p
        className={classnames(styles.offer__additionalContent, {
          [styles.offer__additionalContentIsDisabled]: isDisabled,
          [styles.offer__additionalContentIsInvisible]: price === 'Free',
        })}>
        Please contact me at{' '}
        <a href="mailto:hello@gregdlubacz.com">hello@gregdlubacz.com</a> if you
        need a license for more than one person.
      </p>
    </section>
  );
};

Offer.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  ).isRequired,
  illustration: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(Object.values(OFFER_TYPES)).isRequired,
};

const Features = ({ isDisabled, features }) => (
  <div
    className={classnames(styles.features, {
      [styles.featuresIsDisabled]: isDisabled,
    })}>
    <p>You will get</p>
    <ul className={styles.featuresList}>
      {features.map((Feature, index) => (
        <li className={styles.featuresList__item} key={index}>
          <span>{isFunction(Feature) ? <Feature /> : Feature}</span>
        </li>
      ))}
    </ul>
  </div>
);

Features.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  ).isRequired,
};

const Button = ({ children, isDisabled, offerLink }) => (
  <a
    className={classnames(styles.button, {
      [styles.buttonIsDisabled]: isDisabled,
    })}
    href={offerLink}>
    {children}
  </a>
);

Button.propTypes = {
  children: PropTypes.any.isRequired,
  offerLink: PropTypes.string.isRequired,
};

export default Offer;
