import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classnames from 'classnames';

import styles from './MainNav.module.scss';
import FigmaLogo from '../images/figma-logo.inline.svg';
import SketchLogo from '../images/sketch-logo.inline.svg';
import { PRODUCT_LINK } from '../enums/links';

const MainNav = ({ onScrollToFooter }) => {
  const handleDemoLinkClick = (event) => {
    if (event) {
      event.preventDefault();
    }
  };

  return (
    <nav className={styles.mainNav}>
      <ul className={styles.mainNav__list}>
        <MainNavLink
          dropdownElement={MainNavDropdown}
          href="#demo-dropdown"
          onClick={handleDemoLinkClick}>
          Demo
        </MainNavLink>
        <MainNavLink href={PRODUCT_LINK} isPrimary onClick={onScrollToFooter}>
          Buy <span data-hide-on-mobile={true}>&nbsp;illustrations</span>
        </MainNavLink>
      </ul>
    </nav>
  );
};

const MainNavLink = ({
  children,
  childClassName,
  className,
  dropdownElement: Dropdown,
  href,
  isOpened,
  isPrimary,
  onClick,
  target,
}) => {
  return (
    <li
      className={classnames(className, styles.mainNav__item, {
        [styles.mainNav__itemWithDropdown]: Dropdown,
      })}>
      <a
        className={classnames(childClassName, styles.mainNav__link, {
          [styles.mainNav__linkIsPrimary]: isPrimary,
        })}
        href={href}
        onClick={onClick}
        target={target}>
        {children}
      </a>
      {Dropdown && <Dropdown isOpened={isOpened} />}
    </li>
  );
};

const MainNavDropdown = () => (
  <ul className={styles.mainNavDropdown}>
    <MainNavLink
      className={styles.mainNavDropdown__item}
      childClassName={styles.mainNavDropdown__link}
      href="https://www.sketch.com/s/eb242af5-821d-4980-a37e-fe52dfcccbc9"
      target="_blank">
      <SketchLogo className={styles.mainNavDropdown__illustration} />
      Sketch cloud
    </MainNavLink>
    <MainNavLink
      className={styles.mainNavDropdown__item}
      childClassName={styles.mainNavDropdown__link}
      href="https://www.figma.com/file/rRFN76UGBVf67WrvZ6v9Yt/Whoooa-Illustrations-DEMO?node-id=13%3A13843"
      target="_blank">
      <FigmaLogo
        className={classnames(
          styles.mainNavDropdown__illustration,
          styles.mainNavDropdown__illustrationFigmaStyle
        )}
      />
      Figma
    </MainNavLink>
  </ul>
);

MainNav.propTypes = {
  onScrollToFooter: null,
};

MainNavLink.propTypes = {
  children: PropTypes.any.isRequired,
  dropdownElement: PropTypes.func,
  href: PropTypes.string.isRequired,
  isOpened: PropTypes.bool,
  isPrimary: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

MainNavLink.defaultProps = {
  dropdownComponent: null,
  isOpened: false,
  isPrimary: false,
  onClick: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
};

export default MainNav;
